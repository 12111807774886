import "./src/styles/global.scss";

const onClientEntry = () => {
  const userLang = navigator.language.substr(0, 2);

  if (userLang !== "en" && userLang !== "es") {
    if (
      !window.location.pathname.startsWith("/en") &&
      !window.location.pathname.startsWith("/es")
    ) {
      window.location.pathname = `/en${window.location.pathname}`;
    }
  }
};

export { onClientEntry };
