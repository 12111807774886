exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-en-js": () => import("./../../../src/pages/blog/index.en.js" /* webpackChunkName: "component---src-pages-blog-index-en-js" */),
  "component---src-pages-blog-index-es-js": () => import("./../../../src/pages/blog/index.es.js" /* webpackChunkName: "component---src-pages-blog-index-es-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{Mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-cookies-en-js": () => import("./../../../src/pages/cookies.en.js" /* webpackChunkName: "component---src-pages-cookies-en-js" */),
  "component---src-pages-cookies-es-js": () => import("./../../../src/pages/cookies.es.js" /* webpackChunkName: "component---src-pages-cookies-es-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-es-js": () => import("./../../../src/pages/faq.es.js" /* webpackChunkName: "component---src-pages-faq-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-en-js": () => import("./../../../src/pages/jobs.en.js" /* webpackChunkName: "component---src-pages-jobs-en-js" */),
  "component---src-pages-jobs-es-js": () => import("./../../../src/pages/jobs.es.js" /* webpackChunkName: "component---src-pages-jobs-es-js" */),
  "component---src-pages-legal-en-js": () => import("./../../../src/pages/legal.en.js" /* webpackChunkName: "component---src-pages-legal-en-js" */),
  "component---src-pages-legal-es-js": () => import("./../../../src/pages/legal.es.js" /* webpackChunkName: "component---src-pages-legal-es-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-product-en-js": () => import("./../../../src/pages/product.en.js" /* webpackChunkName: "component---src-pages-product-en-js" */),
  "component---src-pages-product-es-js": () => import("./../../../src/pages/product.es.js" /* webpackChunkName: "component---src-pages-product-es-js" */),
  "component---src-pages-solution-orchestration-en-js": () => import("./../../../src/pages/solution-orchestration.en.js" /* webpackChunkName: "component---src-pages-solution-orchestration-en-js" */),
  "component---src-pages-solution-orchestration-es-js": () => import("./../../../src/pages/solution-orchestration.es.js" /* webpackChunkName: "component---src-pages-solution-orchestration-es-js" */),
  "component---src-pages-solution-rpa-en-js": () => import("./../../../src/pages/solution-rpa.en.js" /* webpackChunkName: "component---src-pages-solution-rpa-en-js" */),
  "component---src-pages-solution-rpa-es-js": () => import("./../../../src/pages/solution-rpa.es.js" /* webpackChunkName: "component---src-pages-solution-rpa-es-js" */),
  "component---src-pages-solution-workflow-en-js": () => import("./../../../src/pages/solution-workflow.en.js" /* webpackChunkName: "component---src-pages-solution-workflow-en-js" */),
  "component---src-pages-solution-workflow-es-js": () => import("./../../../src/pages/solution-workflow.es.js" /* webpackChunkName: "component---src-pages-solution-workflow-es-js" */),
  "component---src-pages-terms-en-js": () => import("./../../../src/pages/terms.en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-es-js": () => import("./../../../src/pages/terms.es.js" /* webpackChunkName: "component---src-pages-terms-es-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transparency-en-js": () => import("./../../../src/pages/transparency.en.js" /* webpackChunkName: "component---src-pages-transparency-en-js" */),
  "component---src-pages-transparency-es-js": () => import("./../../../src/pages/transparency.es.js" /* webpackChunkName: "component---src-pages-transparency-es-js" */)
}

