module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-EE7LJ66RMV","cookieName":"gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"GTM-WPZMDFK","cookieName":"gdpr-google-tagmanager","dataLayerName":"dataLayer","defaultDataLayer":{"platform":"gatsby"}},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"root":"/work/kuflow-website","gatsbyRemarkPlugins":[{"resolve":"/work/kuflow-website/node_modules/gatsby-remark-images","id":"ed1e9f05-eaa8-53cd-b102-30d518fef915","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/work/kuflow-website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800,"withWebp":true,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/work/kuflow-website/node_modules/gatsby-remark-vscode","id":"21b7fdde-20a1-5a17-b9bd-61e4fa779bb4","name":"gatsby-remark-vscode","version":"3.3.1","modulePath":"/work/kuflow-website/node_modules/gatsby-remark-vscode/index.js","pluginOptions":{"plugins":[],"theme":"Monokai"},"nodeAPIs":["createResolvers"],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"withWebp":true,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
